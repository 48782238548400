import { render, staticRenderFns } from "./HWP_Normatives.vue?vue&type=template&id=705862d7&scoped=true&"
import script from "./HWP_Normatives.vue?vue&type=script&lang=js&"
export * from "./HWP_Normatives.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "705862d7",
  null
  
)

export default component.exports