<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
    <MainModal
        :main="{ component: 'Normative', title: 'Нормативні параметри' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_normative)"
        :modal="show_normative_dialog"
        @updateItemModal="normativeUpdateItemModal"
    />
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Нормативні параметри
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom color="success">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="openNormativeCreateDialog" class="grey lighten-4 mr-1"
                   v-bind="attrs"
                   v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Створити нормативний параметр</span>
        </v-tooltip>
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="houseParameterHeaders"
        :items="indicator_items"
        hide-default-footer
        class="custom-table custom-table-1"
        @click:row="onNormativeItemClick"
    >
      <template v-slot:item.icon="{ item }">
        <v-icon
            size="26"
            :color="settings?.general_indicator?.value === item.indicator.id ? 'secondary' : 'success'"
        >
          {{ settings?.general_indicator?.value === item.indicator.id ? 'mdi-star' : 'mdi-ruler' }}
        </v-icon>
      </template>

      <template v-slot:item.indicator.name="{ item }">
        <template v-if="settings?.general_indicator?.value === item.indicator.id">
          <span class="font-weight-medium">
            {{ item.indicator.name }}
          </span>
        </template>
        <template v-else>
          {{ item.indicator.name }}
        </template>
      </template>

      <template v-slot:item.date_start="{ item }">
        <span>
          {{ item.date_start | formatDate }}
        </span>
      </template>
      <template v-slot:item.date_end="{ item }">
        <span>
          {{ item.date_end | formatDate }}
        </span>
      </template>
      <template v-slot:item.value_dec="{ item }">
        <span>
          {{ item.value_dec | formatNumber }}
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {
  FETCH_FLAT_INDICATOR_TYPES,
  FETCH_FLAT_INDICATORS,
} from "@/store/actions/flat_indicators";
export default {
  name: "HWP_Normatives",
  props: {
    actual: {
      type: Boolean,
      default: false
    },
    inner_person_hash: {
      type: String,
      default() {
        return ''
      }
    }
  },
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  data() {
    return {
      houseParameterHeaders: [
        { text: '', value: 'icon', width: 26 },
        { text: 'Назва параметра', value: 'indicator.name' },
        { text: 'Одн.вим', value: 'indicator.unit_short_name' },
        { text: 'Значення', value: 'value_dec' },
        { text: 'Початок', value: 'date_start' },
        { text: 'Закінчення', value: 'date_end' },
      ],
      selected_normative: {},
      show_normative_dialog: false,
    }
  },
  computed: {
    ...mapGetters({
      indicators: 'getFLatIndicators',
      flat: 'getFlat',
      settings: 'getGeneralSettings',
      current_month: 'getCurrentMonth'
    }),
    indicator_items() {
      if (this.actual) {
        return this.indicators.filter(item => item.actual)
      } else {
        return this.indicators
      }
    }
  },
  methods: {
    ...mapActions({
      fetchIndicators: FETCH_FLAT_INDICATORS,
      fetchIndicatorTypes: FETCH_FLAT_INDICATOR_TYPES
    }),
    normativeUpdateItemModal(payload) {
      this.show_normative_dialog = false
      this.selected_normative = {}

      if (payload) {
        if (payload.payload === 'fetch') {
          this.$emit('fetch')
        }
      }
    },
    openNormativeCreateDialog() {
      this.selected_normative = {
        flat_name: this.flat.full_address,
        person_hash: this.flat.person_hash,
        actual: this.actual,
        date_start: this.current_month
      }
      this.show_normative_dialog = true
    },
    onNormativeItemClick(payload) {
      const local_payload = JSON.parse(JSON.stringify(payload))
      local_payload.flat_name = this.flat.full_address
      local_payload.person_hash = this.flat.person_hash
      local_payload.indicator_id = payload.indicator.id
      local_payload.name = payload.indicator.name
      local_payload.actual = this.actual

      this.selected_normative = local_payload
      this.show_normative_dialog = true
    },
  },
  created() {
    const person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
    this.fetchIndicators(person_hash)
        .then(res => {
          if (res) {
            this.fetchIndicatorTypes()
          }
        })
  }
}
</script>

<style scoped lang="scss">

</style>